import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';

//import App from "./App";
import Contact from "./components/Contact";
import Home from "./components/Home";
import Meets from './components/schedules/Meets';
import AboutRisingStars from './components/about/AboutRisingStars';
import OurTeam from './components/about/OurTeam';
import NotFound from "./components/NotFound";
import TopBar from "./TopBar";
import BottomButtons from './BottomButtons';


const root = ReactDOM.createRoot(document.getElementById('root'));

const {
  REACT_APP_PRIMARY_COLOR,
  REACT_APP_SECONDARY_COLOR,
  REACT_APP_PRIMARY_TEXT_COLOR,
  REACT_APP_PRIMARY_BACKGROUND_COLOR,
  REACT_APP_SECONDARY_BACKGROUND_COLOR,
  REACT_APP_FONT_FAMILY

} = process.env;

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: REACT_APP_PRIMARY_COLOR,
    },
    secondary: {
      main: REACT_APP_PRIMARY_COLOR,
      dark: REACT_APP_SECONDARY_BACKGROUND_COLOR
    },
    info: {
      light: REACT_APP_SECONDARY_COLOR,
      main: REACT_APP_PRIMARY_COLOR,
      dark: REACT_APP_PRIMARY_COLOR
    },
    background: {
      default: REACT_APP_PRIMARY_BACKGROUND_COLOR,
      paper: REACT_APP_SECONDARY_BACKGROUND_COLOR,
    },
    text: {
      primary: REACT_APP_PRIMARY_TEXT_COLOR,
      secondary: REACT_APP_PRIMARY_COLOR
    }

  },
  typography: {
    fontFamily: REACT_APP_FONT_FAMILY,
    fontWeightRegular: 25,
    fontWeightBold: 25,
    body1: {
      fontFamily: 'Avantgarde, TeX Gyre Adventor, URW Gothic L, sans-serif',
      fontWeight: 800
    }
  }
});

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <BrowserRouter basename='/public'>
      <Box sx={{paddingBottom: "60px", position: "relative"}}>
        <TopBar />
        <Routes>             n
          <Route path="contact" element={<Contact />} />
          <Route path="home" element={<Home />} />
          <Route path="schedules/meets" element={<Meets />} />
          <Route path="about/our-team" element={<OurTeam />} />
          <Route path="about/about-rising-stars" element={<AboutRisingStars />} />
          <Route path="/" element={<Home />} />

          <Route path="*" element={<NotFound />} />

        </Routes>
        </Box>
        <BottomButtons />
        
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);

