import React, { useState } from 'react';
import { useNavigate } from 'react-router';

import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/system/Container';
import Styles from './styles';
import Notifications from './components/Notifications';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import darkThemeLogo from './img/rising_stars_dark_2.png';
import {JACKRABBIT_URL} from './constants'


// import BasicTabs from './BasicTabs';

export default function TopBar() {
    const {
        REACT_APP_JACKRABBIT_ORGANIZATION_ID
    } = process.env;
    const classSchedule = `${JACKRABBIT_URL}/jr3.0/Openings/OpeningsDirect?OrgID=${REACT_APP_JACKRABBIT_ORGANIZATION_ID}`;
    const registrationForm = `${JACKRABBIT_URL}/regv2.asp?id=${REACT_APP_JACKRABBIT_ORGANIZATION_ID}`;
    const navigate = useNavigate();
    const theme = useTheme();
    const styles = Styles();

    const [anchorEl, setAnchorEl] = useState(null);
    // const [schedulesOpen, setSchedulesOpen] = useState(false);
    const open = Boolean(anchorEl)
    const handleNavigate = (route) => {
        navigate(route);
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box sx={{ flexGrow: 1, height: '80px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <AppBar position='static' sx={styles.topBar}>
                <Container sx={{ width: { xs: "60%" }, display: "flex", flexDirection: "row " }}>
                    <IconButton type="button" onClick={() => navigate('/home')} disableRipple>
                        <Box component='img' alt='Rising Stars Gymnastics' src={darkThemeLogo} sx={{ width: { xs: "90px" }, height: { xs: "53px" } }} />
                    </IconButton>

                </Container>
                <Container sx={{ width: { xs: "40%", md: "30%", lg: "30%" }, display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                    <Notifications />
                    <Box sx={{ flexGrow: 0, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2, color: theme.palette.secondary.main, p: 0 }}
                            onClick={handleClick}

                        >
                            <MenuIcon />
                        </IconButton>
                    </Box>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        sx={{
                            color: theme.palette.secondary.main,
                            fontSize: "15px",
                            flexWrap: "wrap"
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}

                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={() => handleNavigate("about/about-rising-stars")} id="menu-item">About Rising Stars</MenuItem>
                        <MenuItem onClick={() => handleNavigate("about/our-team")} id="menu-item">Our Team</MenuItem>
                        <MenuItem onClick={() => handleNavigate("schedules/meets")} id="menu-item">Meets Schedule</MenuItem>
                        <MenuItem onClick={() => window.open(classSchedule, '_blank', 'noopener,noreferrer')} id="menu-item">Classes Schedule</MenuItem>
                        <MenuItem onClick={() => window.open(registrationForm, '_blank', 'noopener,noreferrer')} id="menu-item">Registration Form</MenuItem>
                        <MenuItem onClick={() => handleNavigate("contact")} id="menu-item">Contact</MenuItem>
                    </Menu>
                </Container>
            </AppBar>


        </Box>
    );
}
