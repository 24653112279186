import { useTheme } from '@mui/material/styles';
export default function Styles() {
    const theme = useTheme();
    const styles = {};
    styles.menuItem = theme.typography.h2;
    styles.h1 = {
        ...theme.typography.h3,
        "textAlign": "center",
        display: "flex",
        justifyContent: "center",
        margin: "10px"

    };
    styles.title = {
        ...theme.typography.h1,
        margin: "10px",
        // position: 'absolute',
        fontSize: "60px",
        color: theme.palette.primary.main,
        top: '10px',
        textAlign: { sm: 'left', md: 'center'}
    };
    styles.overlay = {
        position: 'absolute',
        bottom: '30px',
        right: '30px',
        color: theme.palette.info.light,
        background: 'none'
    };
    styles.image = {
        position: 'relative'
    }
    styles.topBar = {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'space-between'
    }
    styles.imgBorder = {
        borderColor: theme.palette.info.light,
        borderStyle: 'outset',
        borderWidth: "3px"
    }
    styles.formItem = {
        width: {
            xs: "300px",
            md: "400px",
            lg: "400px"
        },
    }
    styles.formInputProps = {
        style: {
            WebkitTextFillColor: "#fff",
            WebkitBoxShadow: "0 0 0 1000px black inset"
        }
    };
    styles.pageHeader = {
        paddingBottom: "15px",
        paddingTop: "15px"
    }
    styles.border = {
        border: `1px solid ${theme.palette.info.light}`
    }
    styles.bottomBorder = {
        borderBottom: `1px solid ${theme.palette.info.light}`
    }
    styles.notificationIcon = {
        // position: 'fixed',
        // bottom: '30px',
        // left: '30px',
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        color: theme.palette.info.light,
        background: 'none',
        alignContent: "flex-end",
        padding: "25px"
    }
    return styles;

}