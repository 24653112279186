import React, { useRef } from "react";
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import Container from '@mui/material/Container';

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS

export default function Table(props) {
    const {
        rowData,
        columnData,
        defaultColDef,
        tableHeight
    } = props;

    const gridRef = useRef();
    const colDefsFlat = !columnData.some((data) => data.children);

    const currencyFormatter = (params) => {
        if (params.value) {
            return `$${params.value.toFixed(2)}`
        }
            return params.value;
    };
    const stringFormatter = (params) => {
        return params.value;
    }
    let columnDefs = [];
    const getValueFormatter = (dataType) => {
        let valueFormatter = () => {};
            switch (dataType) {
                case 'number':
                    valueFormatter = currencyFormatter;
                    break;
                case 'string':
                    valueFormatter = stringFormatter;
                    break
                default :
                    console.log("This data type does not have a formatter yet!");
            }
        return valueFormatter;
    };
    const formatChildren = (data) => data.map(({ field, dataType, width }) => {
        const valueFormatter = getValueFormatter(dataType);
        return {
            field,
            valueFormatter,
            suppressSizeToFit: true,
            width
        };
    });
    if (colDefsFlat) {
        columnDefs = formatChildren(columnData)
    } else {
        columnDefs = columnData.map(({ headerName, children }) => {
            // const def = { headerName };
            const dchildren = formatChildren(children);
            return { headerName, children: dchildren};
        });
    }
    return (
        <Container className="ag-theme-alpine-dark" style={{ height: tableHeight}}>
            <AgGridReact
               ref={gridRef}
               rowData={rowData}
               columnDefs={columnDefs}
               defaultColDef={defaultColDef}
            />
        </Container>
    )

}