import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import moment from "moment";
import { IconButton } from "@mui/material";
import Popover from '@mui/material/Popover';
import { listNotifications } from "../helpers/requests";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import Styles from "../styles";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
export default function Notifications() {
    const styles = Styles();
    const today = moment(new Date()).format('YYYY-MM-DD');
    const defaultNotification = [{ title: "Notifications are Empty!", message: "Check back later for more notifications!", id: 1, priority: "Low", startDate: today, endDate: today }];
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'notifications-popover' : undefined;
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        notifications.forEach(({ id, title }) => localStorage.setItem(`${id}-${title}`, 1));
        setUnreadNotifications(0);
        setAnchorEl(null);
    };
    const [unreadNotifications, setUnreadNotifications] = useState(null);
    const [notifications, setNotifications] = useState([]);
    // const [error, setError] = useState('');
    useEffect(() => {
        listNotifications()
            .then((res) => {
                let filteredNotifications = [];
                filteredNotifications = res.data.filter(({ startDate, endDate }) => moment(startDate).isSameOrBefore(today) && moment(endDate).isSameOrAfter(today));
                if (filteredNotifications.length === 0) {
                    filteredNotifications = defaultNotification;
                }
                setNotifications(filteredNotifications);
                const notificationsNotInStorage = filteredNotifications.filter(({ id, title }) => !JSON.parse(localStorage.getItem(`${id}-${title}`)));
                setUnreadNotifications(notificationsNotInStorage.length);
            })
            .catch((err) => {
                console.log({ err });
                // setError(err);
            });
    });
    const notificationCards = notifications.map(({ title, message, id, priority }) => {
        const key = `rising-stars-lander-notification-${id}`;
        return (
            <Card key={key} variant="outlined" sx={{width: "250px"}}>
                <CardContent>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                        {priority === 'High' ? (
                            <Badge badgeContent={"!"} color="error" variant="string">
                                <Typography variant="h6" component="div">
                                    {title}
                                </Typography>
                            </Badge>
                        ) : (
                            <Typography variant="h6" component="div">
                                {title}
                            </Typography>
                        )}

                    </Box>
                    <Typography variant="body1" component="div">
                        {message}
                    </Typography>
                </CardContent>
            </Card>
        )
    });
    return (
        <Box sx={styles.notificationIcon}>

            <IconButton color="info" aria-describedby={id} onClick={handleClick} size="small">
                <Badge badgeContent={unreadNotifications} color="error">
                    <NotificationsNoneIcon />
                </Badge>

            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {notificationCards}
            </Popover>


        </Box>
    )
}