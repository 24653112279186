import React from 'react';
import Carousel from 'react-material-ui-carousel'
// import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import rising_stars_lander_practice from '../img/rising_stars_lander_practice.jpg';
import rising_stars_lander_podium from '../img/rising_stars_lander_podium.jpeg';
import Styles from '../styles';

export default function HomeCarousel()
{
    var items = [
        {
            name: "Rising Stars Lander - Practice",
            description: "Work hard and learn discipline",
            src: rising_stars_lander_practice,
            order: 1
        },
        {
            name: "Rising Stars Lander - Girls on podium",
            description: "Compete as part of a team!",
            src: rising_stars_lander_podium,
            order: 2
        }
    ];
    const sortedItems = items.sort((a, b) => a.order - b.order);
    // <Typography component="p" sx={styles.overlay}>{props.item.description}</Typography>
    return (
        <Carousel>
            {
                sortedItems.map( (item, i) => {
                    const key = `rising-stars-lander-carousel-image-${i}`;
                    return (
                        <Item key={key} item={item} /> 
                    )
            })
            }
        </Carousel>
    )
}

function Item(props)
{
    const styles = Styles();
    return (
        <Container>
            <Container sx={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
            <Box component="img" sx={{ height: {xs: 300, sm: 500, md: 530}, width: {xs: 300, sm: 500, md: 530}, alignSelf:"center", ...styles.imgBorder}} alt={props.item.description} src={props.item.src} />
            
            </Container>
            
        </Container>
        
    )
}
