import React, { useState } from "react";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import emailjs from 'emailjs-com';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useTheme } from "@emotion/react";
import MuiPhoneNumber from "material-ui-phone-number";
import CircularProgress from '@mui/material/CircularProgress';
import Typography from "@mui/material/Typography";
import Styles from "../styles";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Contact() {
    const {
        REACT_APP_TEMPLATE_ID,
        REACT_APP_USER_ID,
        REACT_APP_SERVICE_ID
    } = process.env;
    const theme = useTheme();
    const styles = Styles();
    const [method, setMethod] = useState('');
    const [message, setMessage] = useState('');
    const [contact, setContact] = useState('');
    const [error, setError] = useState(null);
    const [inProgress, setInProgress] = useState(false);
    const [open, setOpen] = useState(false);
    const [contactValid, setContactValid] = useState(true);
    const contactTypes = [
        { label: 'Email' },
        { label: 'Phone' },
        { label: 'None' },
        { label: '' }
    ];
    function sendEmail(e) {

        e.preventDefault();
        setInProgress(true);
        emailjs.sendForm(REACT_APP_SERVICE_ID, REACT_APP_TEMPLATE_ID, e.target, REACT_APP_USER_ID)
            .then((result) => {
                if (result.status === 200) {
                    setInProgress(false);
                    setOpen(true);
                    setMessage('');
                    setMethod('');
                    setContact('');
                }
            }, (error) => {
                console.log(error.text);
            })
            .catch((err) => console.log({ err }))
    }
    const handleEnableButton = () => {
        if (!message.length) {
            return true;
        }
        if ((method !== 'None' && method !== '') && (!contact.length || !contactValid)) {
            return true;
        }
        return false;
    }
    const handleMethodChange = (e, v) => {
        e.preventDefault();
        setContactValid(true);
        if (!v) {
            setMethod('None');
        } else {
            setMethod(v.label);
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleMessageChange = (e, v) => {
        setMessage(e.target.value)
    };
    const handlePhoneNumberChange = (e, _) => {
        setContact(e)
    }
    function isValidContact(contact) {
        if (!contact.length) {
            return true;
        } else if (method === 'Phone') {
            return /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(contact);
        } else if (method === 'Email') {
            return /\S+@\S+\.\S+/.test(contact);
        } else {
            return true;
        }
    }
    const handleEmailChange = (e, v) => {
        setContact(e.target.value);

    }
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!contactValid) {
            setError("invalid contact, please fix");
        } else {
            sendEmail(e);
        }
    }
    const handleContactCapture = (e) => {
        if (!isValidContact(e.target.value)) {
            setContactValid(false);
        } else {
            setContactValid(true);
        }
    }
    const handleFocus = (e) => {
        setContactValid(true);
    }
    return (

        <Container sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="h2" component="h2" sx={{...styles.pageHeader, textAlign: "center"}}>Contact</Typography>
            <Container sx={{ display: "flex", justifyContent: "center"}}>
                <Grid container spacing={1} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", width: {xs: "100px"} }}>
                <form onSubmit={handleSubmit}> 

                    <Grid item>

                        <FormControl variant="filled" color="secondary" hiddenLabel sx={styles.formItem}>
                            <Autocomplete
                                id="contact-type"
                                disablePortal
                                aria-hidden
                                options={contactTypes}
                                filterOptions={labelList => labelList.filter(opt => opt.label)}
                                isOptionEqualToValue={(option, value) => option.label === value}
                                value={method}
                                sx={styles.formItem}
                                onChange={handleMethodChange}
                                
                                renderInput={(params) => <TextField {...params} value={method}
                                    label="Preferred Contact Method" />
                                } >
                            </Autocomplete>
                        </FormControl>
                    </Grid>

                    {method === 'Email' && (<Grid item >
                        <FormControl style={styles.formItem} error={!contactValid} >
                            <TextField
                                id="email-input" 
                                placeholder="myemail@example.com" 
                                error={!contactValid} 
                                aria-describedby="email-helper" 
                                onChange={handleEmailChange}
                                onBlur={handleContactCapture} 
                                variant="outlined" 
                                name="contact" 
                                onFocus={handleFocus} 
                                sx={styles.formItem}
                                inputProps={styles.formInputProps}
                            />
                            <FormHelperText id="email-helper" sx={{color: theme.palette.info.light}}>{contactValid ? "We will never share your email" : "Please enter a valid email address"}</FormHelperText>
                        </FormControl>


                    </Grid>)}
                    {method === 'Phone' && (<Grid item>
                        <FormControl style={styles.formItem} error={!contactValid}>
                            <MuiPhoneNumber
                                name="contact"
                                label="Phone Number"
                                data-cy="user-phone"
                                defaultCountry={"us"}
                                error={!contactValid}
                                value={contact}
                                sx={styles.formItem}
                                onChange={handlePhoneNumberChange}
                                onBlur={handleContactCapture}
                                onFocus={handleFocus}
                                inputProps={styles.formInputProps}
                            />
                            <FormHelperText id="phone-helper" sx={{color: theme.palette.info.light}}>{contactValid ? "We will never share your phone" : "Please enter a valid phone number"}</FormHelperText>
                        </FormControl>

                    </Grid>)}
                    <Grid item>
                        <FormControl style={styles.formItem}>
                            <TextField
                                id="message"
                                label="Message"
                                required
                                sx={styles.formItem}
                                name="message"
                                multiline
                                rows={6}
                                value={message}
                                onChange={handleMessageChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item style={styles.formItem}>
                        <Button type="submit" color="secondary" variant="contained" fullWidth disabled={handleEnableButton()}>Submit</Button>
                    </Grid>
                    {inProgress && (
                        <Grid item sx={{ display: "flex", justifyContent: "center", width: {xs: "200px", md: "400px"} }}>
                            <CircularProgress />
                        </Grid>

                    )}
                    {error && <h2 style={{ color: 'red' }}>{error}</h2>}
                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                            Your message was sent successfully!
                        </Alert>
                    </Snackbar>
                   </form>
                </Grid>
                </Container>
            
        </Container>
    )
}