import React from "react";
import Styles from "../../styles";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import HeaderDivider from "../../HeaderDivider";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import rising_stars_lander_team_huddle from "../../img/rising_stars_lander_team_huddle.jpeg";
import {useNavigate} from "react-router";

export default function AboutRisingStars() {
    const styles = Styles();
    const navigate = useNavigate();
    return (
        <Container>
            <Typography component="h2" variant="h2" sx={styles.pageHeader}>About</Typography>
            <HeaderDivider />
            <Grid container>
                <Grid item xs={12} sx={{display: "flex", flexDirection: "row", justifyContent: "center", marginBottom: "10px" }}>
                    <Box component="img" sx={{ height: {xs: 250, md: 400}, width: {xs: 300, md: 500}, alignSelf:"center"}} alt="Team Huddle" src={rising_stars_lander_team_huddle} />
                </Grid>
                <Grid item xs={12} sx={{ fontWeight: "light" }}>
                    {`Rising Stars Gymnastics is located in Lander Wyoming. \n
                    We have class offerings for ages from infants through high school! \n
                    We believe that through hard work and dedication, anything is possible! \n
                    Below is contact information for our main two coaches, or you can submit a message \n
                    through our website`}
                </Grid>
                
                <Grid item xs={12} sx={{ marginTop: "15px"}}>
                    <Grid container sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "space-between"}}>
                        <Grid item xs={6}>
                            Coach Cami's Phone:
                            <Link href="tel:+18012287805">(801)-228-7805</Link>
                        </Grid>
                        <Grid item xs={6}>
                            Coach Sarah's Phone: 
                            <Link href="tel:+13215053833">(321)-505-3833</Link>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ padding: "0px"}}>
                    <Button size='large' sx={{ padding: "0px", marginTop: "15px"}} onClick={() => navigate('/contact')}>Send a Message Here</Button>
                </Grid>
                <Grid item xs={12} sx={{ padding: "0px"}}>
                    <Button size='large' sx={{ padding: "0px", marginTop: "15px"}} onClick={() => navigate('/about/our-team')}>Meet Our Team</Button>
                </Grid>
            </Grid>

        </Container>
    )
    
}