import React from "react";
import Styles from "../styles";
import { useNavigate } from 'react-router';
import Button from '@mui/material/Button';
import { Container } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';
import HomeCarousel from "./HomeCarousel";
export default function Home() {
    const navigate = useNavigate();
    const styles = Styles();
    const handleNavigate = (route) => {
        navigate(route);
    };
    return (

        <Box flexGrow={1} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", position: "relative" }}>
            <Container>
            <Typography component="h1" variant="h1" sx={styles.title}>Rising Stars Gymnastics</Typography>
            </Container>
            
            <HomeCarousel />
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "10px" }}>
                <Button variant="outlined" size="large" sx={{ margin: "2px" }} onClick={() => handleNavigate('/about/about-rising-stars')}>About Us</Button>
                <Button variant="outlined" size="large" sx={{ margin: "2px" }} onClick={() => handleNavigate('/schedules/classes')}>Classes</Button>
                <Button variant="outlined" size="large" sx={{ margin: "2px" }} onClick={() => handleNavigate('/contact')}>Contact</Button>
            </Box>
        </Box>

    )
}