import React from "react";
import Divider from "@mui/material/Divider";
import {useTheme} from "@emotion/react";

export default function HeaderDivider() {
    const theme = useTheme();
    return (
        <Divider color={theme.palette.info.light} light sx={{marginBottom: "20px"}} />
    );
}
