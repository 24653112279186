/**
 * Defines the default headers for these functions to work with `json-server`
 */
 const headers = new Headers({
    'Content-Type': 'application/json',
   });
   const {
    REACT_APP_API_SERVER
   } = process.env;
  const MEETS_ROUTE = '/meets';
  const NOTIFICATIONS_ROUTE = '/notifications';
  const API_SERVER = REACT_APP_API_SERVER || 'http://localhost:5000';
  console.log('api server: ', API_SERVER)
   // headers.append("Content-Type", "application/json");
  //headers.append("Access-Control-Allow-Credentials", true);
  //headers.append("Access-Control-Allow-Origin", "https://localhost:3000");
  
  /**
   * Fetch `json` from the specified URL and handle error status codes and ignore `AbortError`s
   *
   * This function is NOT exported because it is not needed outside of this file.
   *
   * @param url
   *  the url for the request.
   * @param options
   *  any options for fetch
   * @param onCancel
   *  value to return if fetch call is aborted. Default value is undefined.
   * @returns {Promise<Error|any>}
   *  a promise that resolves to the `json` data or an error.
   *  If the response is not in the 200 - 399 range the promise is rejected.
   */
  async function fetchJson(path, options, onCancel) {
    const url = new URL(API_SERVER + path);
    try {
      const response = await fetch(url, options);
      if (response.status === 204) {
        return null;
      }

      const payload = await response.json();
      if (payload.status === 200) {
        return payload;
      }
  
      if (payload.error) {
        return Promise.reject({ message: payload.error });
      }
      return payload;
    } catch (error) {
      if (error.name !== "AbortError") {
        throw error;
      }
      console.log("we got here")
      return Promise.resolve(onCancel);
    }
  }
  
  /**
   * Retrieves all existing images
   * @returns {Promise<[reservation]>}
   *  a promise that resolves to a possibly empty array of images saved in the database.
   */
  async function get(path) {
    return await fetchJson(path, {
      method: "GET",
      headers,
      mode: 'cors'
    });
  }

  export async function listMeets() {
    return await get(MEETS_ROUTE);
  }

  export async function listNotifications() {
    return await get(NOTIFICATIONS_ROUTE);
  }

  