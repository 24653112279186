import React from 'react';
// import { useTheme } from "@emotion/react";
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
export default function BottomButtons() {

    return (
        <Box sx={{ marginTop:'calc(10% + 60px)',  position: "fixed", bottom: 0, left: 0, width: "100%" }} flexGrow={1}>
            <Divider sx={{ alignSelf: 'center'}} light variant="middle"/>
            <Box sx={{ display: "flex", justifyContent: "flex-end", marginRight: "20px"}}>
                <IconButton href='https://www.facebook.com/RisingStarsGymntasics' target={'_blank'}>
                    <FacebookIcon sx={{ opacity: 0.4 }} />
                </IconButton>
                <IconButton href='https://www.instagram.com/rising.stars.gymnastics/' target={'_blank'}>
                    <InstagramIcon sx={{ opacity: 0.4 }} />
                </IconButton>
            </Box>
        </Box>
    )
}