import React, { useEffect} from "react";
import { useNavigate } from "react-router";
import { useTheme } from "@emotion/react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import HeaderDivider from "../HeaderDivider";
import CircularProgress from '@mui/material/CircularProgress';
export default function NotFound() {
    const navigate = useNavigate()
    const theme = useTheme();
    useEffect(() => {
        setTimeout(function () {
            navigate('/home');
        }, 2000);
    })
    return (
        <Container sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <Typography variant="h2" component="h2">404 Not Found</Typography>
            <HeaderDivider />
            <Typography variant="p" component="p" color={theme.palette.text.secondary}>Looks like you got lost. Let's take you back home...</Typography>
            <CircularProgress sx={{marginTop: "15px"}} />
        </Container>

    )
}