import React, { useState } from "react";
import Styles from "../../styles";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import rising_stars_coach_sarah from "../../img/rising_stars_coach_sarah.jpg";
import rising_stars_coach_annie from "../../img/rising_stars_coach_annie.jpg";
import rising_stars_coach_cami from "../../img/rising_stars_coach_cami.jpg";
import rising_stars_coach_brook from '../../img/rising_stars_coach_brook.jpg';
// import rising_stars_pink_icon from "../../img/rising_stars_pink_icon.png"
import rising_stars_coach_alei from "../../img/rising_stars_coach_alei.JPG"

import HeaderDivider from "../../HeaderDivider";

export default function OurTeam() {
  const styles = Styles();
  const [coach1Expanded, setCoach1Expanded] = useState(false);
  const [coach2Expanded, setCoach2Expanded] = useState(false);
  const [coach3Expanded, setCoach3Expanded] = useState(false);
  const [coach4Expanded, setCoach4Expanded] = useState(false);
  const [coach5Expanded, setCoach5Expanded] = useState(false);
  const [coach6Expanded, setCoach6Expanded] = useState(false);

  const coachesData = [
    {
      name: "Cami Hammond",
      title: "Owner",
      bio: `My name is Cami Hammond!   I am the owner of Rising Stars Gymnastics ! I grew up in Cody Wyoming with 1 sister and 3 brothers!  I grew up competing in gymnastics and playing soccer!  I love all things sports and have a passion for helping others work through difficult tasks, emotional events and physical abilities through gymnastics!  

      I competed gymnastics through High School worked out with the Utah State University gymnastics team my junior and senior year abd then chose to play soccer in college!  My passion for the gym never ended and I coached for Madison Gymnastics Center in Rexburg Idaho until I graduated with my degree in marriage and family studies and child education with a minor in math!  
      
      I married Dr. Jeffery Hammond in the Idaho Falls temple in 2006.  He transferred from BYU Idaho to the university of Wyoming, then attended Creighton University in Omaha Nebraska for his doctorate in dentistry!  
      
      During school we had 5 kids!  Jace, Brinlee, Aleigha, Cache, and Ryder!  I coached at Metro Stars Gymnastics and continued to play soccer on a club adult team!  
      
      We decided to move closer to family and bought two dental practices in Lander and Riverton Wyoming!  Upon moving here we decided to start the gymnastics gym for a place to provide a safe place and positive environment for the youth of Lander!  I am so thankful for what the gym taught me and wanted to provide that for the youth here!  
      
      Our little miracle baby and last one was born in June of 2021!  I stay at home with Breigha and homeschool Aleigha!  I love coaching and providing a place for the community to come and enjoy being physically active and overcoming obstacles in their own lives!  `,
      id: "coach1",
      image: rising_stars_coach_cami,
      altImg: "Rising Stars Lander Coach Cami Hammond with Family",
      expanded: coach1Expanded
    },
    {
      name: "Sarah Taylor",
      title: "Coach",
      bio: `I grew up in in a big family in Southern Utah. Being active and playing sports has always been a huge part of my life. I grew up playing sports with my siblings and playing volleyball, basketball and cheerleading in high school. I discovered my passion for running after high school. I've run 23 half marathons and 9 full marathons including the prestigious Boston Marathon. I'm usually training for a race and coaching a runner or two as well.
        My husband Trevor and I got married in 2005. We have 5 beautiful children. Three girls and two boys. As a family we love doing anything outdoors. My two older daughters compete in gymnastics and my youngest is on preteam. I've been coaching at Rising Stars Gymnastics since it opened in 2018. I love the sport of gymnastics and truly love the girls I coach! I love watching their faces light up when they've worked so hard and mastered a new skill. I am a firm believer that with hard work and self discipline ANYTHING is possible!`,
      id: "coach2",
      image: rising_stars_coach_sarah,
      altImg: "Rising Stars Lander Coach Sarah Taylor with Family",
      expanded: coach2Expanded
    },
    {
      name: "Annie Butler",
      title: "Front Office and Coach",
      bio: `I am 15 years old, and a sophomore in high school. I love working with little kids. In my spare time I like to go rock climbing and work out. I also enjoy reading and going to all the sports games at the high school. I love the cold weather and really enjoy snowboarding.`,
      id: "coach3",
      image: rising_stars_coach_annie,
      altImg: "Rising Stars Lander Coach Annie Butler",
      expanded: coach3Expanded
    },
    {
      name: "Brooklyn White",
      title: "Student Coach",
      bio: `Brooklyn is currently a level six gymnast. This will be her fifth year in gymnastics. She likes teaching private lessons at the gym. She likes riding horses and doing peoples nails.  There are nine people in her family. 
      She has fun spending time with them. She enjoys baking yummy desserts.`,
      id: "coach4",
      image: rising_stars_coach_brook,
      altImg: "Rising Stars Lander Student Coach Brooklyn White",
      expanded: coach4Expanded
    },
    {
      name: "Aleigha Hammond",
      title: "Student Coach",
      bio: `Aleigha Is currently a level 6 Gymnasts! She has been doing gymnastics for five years! She loves to bake desserts, coach private lessons at the gym hunting with her family and all things outdoors!  
      I coach four classes, which are preschool parkour and homeschool! I love to help with little kids! 
      I have five siblings two sisters and three brothers! I am 11 years old I have an older brother that is 15 years old an older sister that is 13 years old a younger brother that is nine years old another younger brother that is seven years old and my baby sister that is almost 18 month old!  .`,
      id: "coach6",
      image: rising_stars_coach_alei,
      altImg: "Rising Stars Lander Student Coach Aleigha Hammond",
      expanded: coach6Expanded
    }
    

  ]
  const handleExpand = (e) => {
    switch (e.target.id) {
      case "coach1":
        setCoach1Expanded(!coach1Expanded);
        break;
      case "coach2":
        setCoach2Expanded(!coach2Expanded);
        break;
      case "coach3":
        setCoach3Expanded(!coach3Expanded);
        break;
      case "coach4":
      setCoach4Expanded(!coach4Expanded);
        break;
      case "coach5":
      setCoach5Expanded(!coach5Expanded);
        break;
      case "coach6":
          setCoach6Expanded(!coach6Expanded);
            break;
      default:
        console.log(`ID, ${e.target.id}, is not recognized`)
    }
  };
  // sx={{ height: { xs: "325px", sm: "325px", lg: "550px" } }}
  const coachesCards = coachesData.map(({ name, title, bio, image, altImg, id, expanded, attr }) => {
    const key=`rising-stars-lander-coach-${id}`
    return (
      <Grid item xs={12} md={6} key={key}>
        <Paper elevation={10}>
          <Card variant='outlined'>
            <div style={{ display: "flex", flexDirection: "column", position: 'relative', justifyContent: "flex-end", alignItems: "flex-end" }}>
              <CardMedia
                component="img"
                src="someurl.url.com"
                sx={{ height: { xs: "325px", sm: "570px" } }}
                image={image}
                alt={altImg}
              />
            </div>

            <CardContent>
              <Typography variant="h4" component="h4">
                {name}
              </Typography>
              <Typography color="#949292" variant="h6" component="h6">
                {title}
              </Typography>
            </CardContent>
            {expanded && (
              <CardContent>
                <Typography variant="p" component="p">
                  {bio}
                </Typography>
              </CardContent>
            )}
            <CardActions>
              <Button id={id} size="small" onClick={handleExpand}>{expanded ? "See Less" : "Learn More"}</Button>
            </CardActions>
          </Card>
        </Paper>
      </Grid>
    )
  })
  return (
    <>
      <Container>
        <Typography variant="h2" component="h2" sx={styles.pageHeader}>Our Team</Typography>
        <HeaderDivider />
        <Grid container spacing={2} sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, justifyContent: "space-between" }}>
          {coachesCards}
        </Grid>
      </Container>
    </>

  )

}