import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Container from "@mui/material/Container";
import Styles from "../../styles";
import Table from "../Table";
import Typography from "@mui/material/Typography";
import HeaderDivider from "../../HeaderDivider";
import { listMeets } from "../../helpers/requests";
import CircularProgress from '@mui/material/CircularProgress';

export default function Meets() {
    const styles = Styles();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [meetsData, setMeetsData] = useState([]);
    const refreshData = () => {
        setLoading(true)
        return listMeets()
            .then((res) => {
                setMeetsData(res.data.map((row) => ({
                    ...row,
                    location: `${row.city}, ${row.state}`
                })))
                setLoading(false)
            })
            .catch((err) => console.log({ err }))
    }

    useEffect(() => {
        refreshData()
            .catch((err) => err ? navigate('/home') : null)
            // eslint-disable-next-line
    }, []);

    const colData = [
        { field: "startDate", dataType: "string" },
        // {field: "endDate", dataType: "string"},
        { field: "location", dataType: "string" },
        // {field: "state", dataType: "string", width: 100},
        { field: "teamCost", dataType: "number" },
        { field: "individualCost", dataType: "number" },
        { field: "deadline", dataType: "string" }
    ];
    const defaultColDef = {
        width: 150
    };
    return (
        loading ? (
            <Container xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
            </Container>
        ) : (
            <Container>
                <Typography component="h2" variant="h2" sx={styles.pageHeader}>Meets</Typography>
                <HeaderDivider />
                <Table rowData={meetsData} columnData={colData} defaultColDef={defaultColDef} tableHeight={500} />
            </Container>
        )
    )
}